@import '../../less/variables.less';

.container {
    height: 1em;

    .button {
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        padding: 0;
        color: @color-oxford;
        background-color: @site-background;
        z-index: 4;
        transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;

        &.expanded {
            top: -16px;
            right: -12px;
            position: relative;
            box-shadow: 0 3px 10px #ddd;
            background-color: @color-white;
            padding: 16px 12px;
        }

        .selected {
            color: @color-oxford;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .list {
            list-style: none;
            float: left;
            clear: both;
            padding: 16px 0 0 0;
            margin: 0;
            text-align: left;

            li {
                padding-bottom: 16px;

                &:last-child {
                    padding-bottom: 0;
                }

                a {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 17px;
                    color: @color-nevada;
                    display: block;

                    &:hover {
                        color: @color-oxford;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}