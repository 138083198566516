@import "../../../less/variables.less";

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media @from-large {
    &:not(:has(>:only-child)) {
      grid-template-columns: 290px 3fr;
      gap: 40px;
    }

    .mobile-facets {
      display: none;
    }
  }
}


ul.list {
  --grid-mode: auto-fill;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;

  &.auto-fit {
    --grid-mode: auto-fit;
  }

  @media @desktop {
    grid-template-columns: repeat(var(--grid-mode), 290px);
    gap: 40px;
    justify-content: center;
  }

  >li {
    display: grid;
  }
}

.item {
  background: white;
  border-bottom: none;
  color: @color-swamp;
  display: grid;
  grid-template: 'image' min-content
    'info' 1fr;
  justify-items: stretch;

  &:hover {
    border-bottom: none;
    outline: 1px solid @color-iron;

    .info h2 {
      text-decoration: underline;
    }
  }

  picture {
    grid-area: image;

    img {
      width: 100%;
    }
  }

  .info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
  }

  time,
  .category {
    color: @color-nevada;
    padding: 4px 8px;
    font-size: var(--font-size-small);
    text-align: center;
  }

  h2 {
    font-family: @font-family-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    text-wrap: balance;
    margin: 0;
  }

  p {
    margin: 0;
    text-align: center;
    text-wrap: balance;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tags {
    list-style: none;
    padding: 0;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    flex: 1 1 auto;
    align-items: end;
    align-content: end;

    li {
      background: @color-grey-01-5;
      color: @color-nevada;
      border-radius: 4px;
      padding: 0 8px;
    }
  }
}