@import '../../../less/variables.less';


.opening-hours {
  h3 {
    font-family: @font-family-serif;
    font-size: var(--font-size-h3);
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column: 1 / -1;
    gap: 8px 16px;

    li {
      padding: 0;
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      align-items: center;

      span {
        text-wrap: balance;
      }

      span:last-child {
        text-align: end;
      }
    }
  }
}