@import '../../../less/variables.less';

.container {
  max-width: var(--max-content-width);
  margin-inline: auto;
  display: grid;
  grid-template: 'top'
    'opening-hours'
    'contact'
    'faq';
  gap: 32px;

  @media @desktop {
    grid-template: 'top top'
      'contact opening-hours'
      'faq faq'
      / minmax(0, 1fr) minmax(0, 1fr);
    gap: 40px;
  }

  .top {
    grid-area: top;
    display: grid;
    grid-template: 'title'
      'image'
      'ingress';
    gap: inherit;
    justify-items: center;

    @media @desktop {
      grid-template: 'image title' min-content
        'image ingress' 1fr;
      min-height: 140px;
      justify-items: stretch;
      align-items: center;

      row-gap: 16px;
    }

    picture {
      grid-area: image;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
    }

    h2 {
      grid-area: title;
      margin: 0;
      font-size: var(--font-size-h3);
    }

    p {
      grid-area: ingress;
      margin: 0;
      font-size: var(--font-size-h4);
    }
  }

  .contact {
    --icon-size: 22px;
    --icon-color: @color-oxford;
    grid-area: contact;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a,
    p {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      flex: 1 1 auto;
    }

    a {
      background: @color-mint-green;
      border: none;
      color: black;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      background: @color-grey-02;
      margin: 0;
    }
  }

  .opening-hours {
    grid-area: opening-hours;
    background: @color-mint-green;
    padding: 24px;

    @media @mobile {
      margin-bottom: -24px;
      padding-inline: 16px;
    }

    h3 {
      font-family: @font-family-serif;
      font-size: var(--font-size-h3);
      font-weight: bold;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .faq {
    grid-area: faq;
  }
}