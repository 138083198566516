@import '../../less/variables.less';

.info {
    font-weight: bold;
    font-size: 16px;
    color: @color-oxford;

    @media @to-medium {
        font-size: 14px;
    }
}
