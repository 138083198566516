@import '../../../less/variables.less';

// This is needed for specificity
:global(article.sanity) {
  .article-info {
    color: @color-nevada;
    font-size: var(--font-size-small);
    font-family: @font-family-sans-serif;
    display: flex;
    flex-direction: column;

    .no-break {
      width: max-content;
    }

    &:empty {
      display: none;
    }

    span {
      display: block;
    }
  }
}