@import "../../../less/variables.less";

a.wine-magazine {
  border-bottom: none;
  background: white;
  display: grid;
  grid-template:
    'image info' 1fr
    / 114px 2fr;
  gap: 16px;

  @media @desktop {
    grid-template:
      'image'
      'info';
    gap: 0;
  }

  &:hover {
    outline: 1px solid @color-iron;

    h2 {
      text-decoration: underline;
    }
  }


  picture {
    grid-area: image;
    perspective: 1500px;

    img {
      width: 100%;
    }
  }

  .info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;

    @media @desktop {
      gap: 2px;
      padding: 16px 0;
    }
  }

  h2 {
    font-size: 16px;
    color: @color-primary;
    font-family: @font-family-serif;
    margin: 0;
    text-align: center;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    margin: 0;
    color: @color-oxford;
  }
}