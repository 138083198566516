@import '../../less/variables.less';

.facet-button {
    font-weight: bold;
    font-size: 13px;
    line-height: 110%;
    padding: 12px;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    border: 1px solid @color-iron;
    border-radius: @border-radius;
    background: @color-white;

    &:hover {
        background-color: @color-grey-02;
    }

    &.selected {
        background: @color-deep-sea-green;
        color: @color-white;
        border: none;

        &:hover {
            text-decoration: underline;
            background-color: @color-blue-stone;
        }
    }

    &.selectable {
        background: @color-mint-green;
        border: none;

        &:hover {
            text-decoration: underline;
            background-color: @color-chinook;
        }
    }
}