@import '../../../less/variables.less';

.sticker-container {
    --margin-top: 40px;
    page-break-inside: avoid;

    @media @to-medium {
        --margin-top: 16px;
        border-top: 1px solid @color-grey-02;
    }

    .sustainable-container {
        --margin-top: 16px;
    }
}

.sustainable-container {
    position: relative;
    width: fit-content;
    background-color: @color-white;
    padding: 24px 40px;
    margin-top: 40px;

    @media @to-medium {
        margin-top: 16px;
        padding: 24px 32px;
    }

    @media print {
        padding: 0;
    }

    h5 {
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        margin: 0;
    }
}

.sustainable {
    width: 0;
    height: 0;
    border-top: 24px solid @color-grass;
    border-right: 24px solid transparent;
    position: absolute;
    top: 0;
    left: 0;

    @media print {
        display: none;
    }

    &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-top: 23px solid @color-spray;
        border-right: 23px solid transparent;
        top: -24px;
        left: 0;
    }
}