@import '../../../less/variables.less';

.alphabet {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;

    a,
    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        background-color: @color-white;
        border-radius: 4px;
        padding: 4px;
        color: @color-swamp;
        border: 1px solid transparent;
    }

    a {
        color: @color-oxford;
    }

    a:hover {
        text-decoration: underline;
        background-color: @color-grey-02;
        border-color: @color-iron;
    }

    span {
        color: @color-nevada;
        background-color: transparent;
    }
}