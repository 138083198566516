@import "../../../less/variables.less";

.simpleGridItem {
    background-color: @color-white;
    display: flex;
    justify-content: space-between;
    min-height: 84px;
    border: none;
    border-radius: 4px;

    &:hover {
        h2 {
            text-decoration: underline;
        }
    }

    h2 {
        font-family: @font-family-serif-ingress;
        align-content: center;
        font-weight: 500;
        padding-inline: 40px;
        font-size: var(--font-size-h3);
        margin: 0;
        color: @color-swamp;
        line-height: 36px;
    }

    li:has(>&) {
        grid-column: 1 / -1;
    }

    @media @desktop {
        margin-bottom: -20px;
        max-width: var(--max-content-width);
        margin-inline: auto;
        width: stretch;
    }

    picture img {
        width: 84px;
    }
}