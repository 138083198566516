@import "../../../less/variables.less";

.accordion-group {
    display: grid;
    gap: 8px;

    >h2 {
        margin-bottom: 32px; // total space becomes 40px, because of 8px gap
    }
}

// hack to get the right specificity here
:global(article.sanity>section) {

    .accordion-group,
    details.accordion {
        margin-inline: 0;
    }
}

details.accordion {
    background-color: @color-white;
    border-radius: 4px;

    summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        list-style: none;

        &::marker,
        &::-webkit-details-marker {
            // hack for safari
            display: none;
        }
    }

    >* {
        padding: 16px var(--margin-inline, 16px);
    }
}